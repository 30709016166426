import React, { useEffect, useState } from 'react';
import './Dashboard.scss';  
import AppLayout from '../../../components/AppLayout/AppLayout';
import { Typography, Box, Grid } from '@mui/material';
import ModalOnboarding from '../../../components/ModalOnboarding/ModalOnboarding';
import HelpIcon from '@mui/icons-material/Help';
import AppStoreBadge from '../../../assets/img/appstore.svg'
import GooglePlayBadge from '../../../assets/img/googleplay.svg'
import MailUDM from '../../../components/MailUDM/MailUDM';
import { useUser } from '../../../services/userContext';
import { Link} from 'react-router-dom';
import AlerteWarningCustom from '../../../components/AlerteWarningCustom/AlerteWarningCustom';
import Etape from '../../../components/Etape/Etape';
import { hasConsentRGPD } from '../../../services/localStorage';

const Dashboard = () => {
  
  const [isLoading,setIsLoading] = useState(false)
  const [isLoaded,setIsLoaded] = useState(false)
  const {candidacy,notifications,user} = useUser()
  const [modalOnBoarding,setModalOnBoarding] = useState(false)
  const countNotifications = notifications ? notifications?.filter(notifications => notifications.is_read !== true).length : 0


  useEffect(()=>{
    if(user && !hasConsentRGPD(user.email)){
      setModalOnBoarding(true)
    }
  },[user])

  useEffect(()=>{
    if(candidacy){
      setIsLoading(false)
      setIsLoaded(true)
    }else{
      setIsLoading(true)
      setIsLoaded(false)
    }
  },[candidacy])



   /*
 const isClickable = (step) => {
    return navItems.find(item => item.title === step.title) ? true : false
  }

 
  const handleClickStep = (step) => {
    if(isClickable){
      const item = navItems.find(item => item.title === step.title)
      navigate(item.link)
    }
  }*/

  return (
    <AppLayout isLoading={isLoading}>
      <ModalOnboarding isOpen={modalOnBoarding} setIsOpen={setModalOnBoarding} />
      {isLoaded && <>
      
      <Typography variant='h1' marginBottom={1}>Tableau de bord</Typography>
      <Typography variant="body1" sx={{marginBottom:4}}>Vous retrouverez ici les informations et documents à fournir en fonction de votre avancement dans le parcours.</Typography>

      {countNotifications !== 0 && 
      <AlerteWarningCustom>
          <Typography sx={{fontWeight:500,color:'#855C00'}}>Vous avez {countNotifications} nouvelle(s) alerte(s)</Typography>
          <Typography sx={{color:'#855C00'}}>Consultez la liste complète de vos alertes <Link style={{color:'inherit',textDecoration:'underline'}} to="/alertes">ici</Link>.</Typography>
      </AlerteWarningCustom>
      }

      <Box sx={{backgroundColor:'white',border:'1px solid #EDEDED',padding:[2,3,4],borderRadius:'12px'}}>
        <Box sx={{marginBottom:4}}>
          <Typography variant="h3">Mon compte</Typography>
        </Box>

        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography sx={{fontWeight:500,color:'black'}}>Identifiant(s)</Typography>
              {candidacy && candidacy.candidates.map((candidate,key)=> <Typography key={key}>{candidate.email}</Typography>)}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{marginTop:4}}>
          <Typography sx={{marginBottom:2,fontWeight:500,color:'black'}}>Les étapes de votre candidature :</Typography>
          
          <Box className="etapes-container" sx={{display:'flex',gap:2,boxSizing:'border-box',width:'100%',justifyContent:'space-between',flexWrap:['wrap','wrap','inherit']}}>
            <div className='line'></div>
            {candidacy && candidacy.steps.map((step,key)=>(
              <Etape key={key} step={step} candidacy={candidacy} />
            ))}


          </Box>
        </Box>
      </Box>

      <Box sx={{background:'white',border:'1px solid',borderColor:'#EDEDED',paddingX:4,paddingY:3,marginY:2,borderRadius:'12px'}}>
            <Box sx={{display:'flex',alignItems:'center',gap:1,marginBottom: 1}}>
              <Box >
                  <HelpIcon sx={{color:'black'}} />
              </Box>
              <Box>
                <Typography sx={{color:'black',fontWeight:'600'}} variant='h4'>Vous avez une question ? Un changement de situation ?</Typography>
              </Box>
            </Box>
            <Box sx={{wordBreak:'break-word'}}>
            <Typography variant="body1">
                N'hésitez pas à contacter notre équipe : <MailUDM />
                Nous répondrons à toutes vos questions dans les plus brefs délais. <br />
                Pour connaître le contact de votre région, <Link to='/contact' style={{color:'black',fontWeight:'700',textDecoration:'underline'}}>rendez-vous ici</Link>.
            </Typography>
            </Box>
      </Box>

      <Box sx={{padding:[2,2,4]}}>
          <Typography sx={{fontSize:'22px',marginBottom:2}}variant="h3">Télécharger l’application “Devenez Mousquetaires”</Typography>
          <Box variant="body1">
            <Typography variant='body1'>Véritable outil d’accompagnement pour votre parcours de recrutement chez Les Mousquetaires, découvrez notre application !</Typography>
            <Box component="ul" sx={{paddingLeft:'20px'}}>
                <Typography component="li" variant="body1">Calendrier de vos rencontres (physiques ou téléphoniques)</Typography>
                <Typography component="li" variant="body1">Événements</Typography>
                <Typography component="li" variant="body1">L’ensemble des étapes de recrutement</Typography>
                <Typography component="li" variant="body1">Des informations sur le Groupement et les enseignes</Typography>
            </Box>
          </Box>
          <Box sx={{marginTop:2, display:'flex', gap:1}}>
            <a target="_blank"  rel="noreferrer" href="https://apps.apple.com/fr/app/devenez-mousquetaires/id1455985486"><img src={AppStoreBadge} alt="app-store-badge" /></a>
            <a target="_blank"  rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.mousquetaires.appdevenez&hl=fr"><img src={GooglePlayBadge} alt="google-store-badge"/></a>
          </Box>
      </Box>


      </>}
    </AppLayout>
)};

export default Dashboard;